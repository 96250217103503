<template>
  <v-dialog
    v-model="openDialogPriority"
    scrollable
    max-width="50%"
    width=" auto "
    @click:outside="closeDialogPriority()"
  >
    <v-card>
      <v-card-title class="secondary" dark> Mofica Priorità </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card-title>{{ fgInfo.name }}</v-card-title>
        <v-card-subtitle>{{ fgInfo.priorityGroup }}</v-card-subtitle>

        <v-list>
          <template>
            <v-list-item>
              <template>
                <v-list-item-content>
                  <v-list-item-title>Priorità da impatto</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="text-body-1">
                    {{ fgInfo.impactPriority }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <template>
                <v-list-item-content>
                  <v-list-item-title>Priorità da RID</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="text-body-1">
                    {{ fgInfo.ridPriority }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <template>
                <v-list-item-content>
                  <v-list-item-title>Priorità inserita</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-select
                    v-model="priorityValue"
                    :items="prioritySelect"
                    label=""
                    dense
                    class="piStyle"
                  ></v-select>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="closeDialogPriority(false, 'noreload')"
        >
          Annulla
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="closeDialogPriority(false, 'reload')"
        >
          Salva
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    openDialogPriority: {
      default: false,
      type: Boolean,
    },
    fgInfo: {
      type: Object,
    },
  },
  data() {
    return {
      priorityValue: this.fgInfo.insertedPriority,
      prioritySelect: ["00", "01", "02", "03", "04", "05", "06"],
    };
  },
  computed: {},
  methods: {
    closeDialogPriority(value, reload) {
      if (reload === "reload") {
        this.$emit(
          "update-priority-value",
          this.fgInfo.id,
          this.priorityValue,
          reload
        );
      } else {
        this.priorityValue = this.fgInfo.insertedPriority;
      }
      this.$emit("open-dialog-priority", value);
    },
  },
  mounted() {},
};
</script>

<style>
.piStyle {
  width: 50px;
}
.piStyle input {
  text-align: center;
}
</style>
