<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template> <b-breadcrumb :items="breadCrumbs"></b-breadcrumb></template>

    <v-banner single-line sticky dark>
      <v-row>
        <v-col cols="2">
          <BackActionBar @goback="goback"></BackActionBar>
        </v-col>
        <v-col cols="8">
          <ContextualActionBar
            title="Piano Operativo - Dettaglio Failover Group"
          ></ContextualActionBar
        ></v-col>
        <v-col cols="2" class="pr-3 pl-0 pt-6 text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="managePlanActivation()"
              >
                <v-icon v-if="statusPlan == 'Attiva'">mdi-flash</v-icon>
                <v-icon v-else> mdi-close-box</v-icon>
              </v-btn>
            </template>
            <span v-if="statusPlan == 'Attiva'">ATTIVA PIANO</span>
            <span v-else>CHIUDI PIANO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="toggleModalSelectPG(true)"
                ><v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
            </template>
            <span>Esporta sistemi per Priority Group</span>
          </v-tooltip>
          <TextReaderUpdate
            @load="textUpdate = $event"
            :disableAlert="disableAlert"
            :update="true"
          />
          <ModalUpdatePlan
            @open-dialog-update-plan="updateDialogUpdatePlan(false)"
            @open-file-dialog="openFileDialog"
            :updateInfo="openDialogUpdatePlan"
            :info="textUpdate"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="updateDialogUpdatePlan(true)"
              >
                <!-- @click="openFileDialog" -->
                <v-icon>mdi-database-arrow-up</v-icon>
              </v-btn>
            </template>
            <span>INTEGRAZIONE ANALISI DI IMPATTO</span>
          </v-tooltip>
          <v-tooltip bottom v-if="getActivation">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="exportImpactedSystems"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA SISTEMI IMPATTATI</span>
          </v-tooltip>
          <v-btn
            v-else
            rounded
            color="secondary"
            dark
            class="ma-1 menuBtn"
            disabled
            @click="exportImpactedSystems"
            ><v-icon>mdi-download</v-icon>
          </v-btn>
          <!--   <ModalUpdatePlan
            @open-dialog-update-plan="toggleDialogUpdatePlan"
            :updateInfo="openDialogUpdatePlan"
            :info="textUpdate"
          /> -->
        </v-col>
        <v-col cols="12" class="d-flex flex-row justify-start align-center">
          <h6>
            <span class="secondary--text">PG</span>&nbsp;
            <em class="font-weight-regular">{{ fgData.priorityGroup }}</em>
            &emsp;
            <span class="secondary--text">Nome</span>&nbsp;
            <em class="font-weight-regular">{{ fgData.name }}</em>
          </h6>
        </v-col>

        <v-col cols="12">
          <input v-model="nodeFilter" placeholder="nodo" class="inputSearch" />
          <v-btn
            rounded
            color="secondary"
            dark
            class="ma-1 btnSearch"
            @click="filter()"
          >
            Filtra
          </v-btn>
          <v-btn
            rounded
            color="secondary"
            dark
            class="ma-1 btnSearch"
            @click="toggleDialogEditServices(true)"
          >
            Modifica Servizi
          </v-btn>
        </v-col>
      </v-row>
    </v-banner>
    <v-alert type="success" v-if="successMsg">
      Salvataggio avvenuto con successo.
    </v-alert>
    <v-alert type="error" v-if="errorMsg">
      Errore durante il salvataggio.
    </v-alert>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto pt-0"
      max-height="calc(100vh - 157px)"
      color="accent"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="pa-0" v-if="items.length > 0">
            <perfect-scrollbar>
              <template v-for="(item, index) in items">
                <v-col
                  v-if="item.action != 'no impact'"
                  :key="index"
                  cols="12"
                  class="pb-0"
                >
                  <FailoverGroupDetail
                    :fgData="item"
                    :idPlan="idPlan"
                    :idService="fgData.id"
                    @reload-failover-group="reloadFailoverGroup"
                  ></FailoverGroupDetail>
                </v-col>
              </template>

              <template>
                <v-col cols="12" class="pb-0">
                  <v-card>
                    <v-card-subtitle
                      class="white--text text-overline blue-grey pa-2"
                    >
                      SERVICE SENZA NODI DA RIAVVIARE
                    </v-card-subtitle>
                    <v-card-text>
                      <v-list dense>
                        <v-list-item
                          v-for="(item, i) in itemunimpactedServices"
                          :key="i"
                          class="pa-0"
                        >
                          <v-btn
                            icon
                            outlined
                            class="mr-2"
                            color="primary"
                            @click="openCardDetails(item.ucmdbid)"
                          >
                            <v-icon color="primary"
                              >mdi-card-text-outline</v-icon
                            >
                          </v-btn>
                          <v-list-item-content>
                            <v-list-item-title class="text-overline">
                              {{ item.displayLabel }}&emsp;&emsp;<span
                                class="text-overline font-weight-bold"
                                >D&D:</span
                              >
                              {{ item.dd }}&emsp;&emsp;<span
                                class="text-overline font-weight-bold"
                                >Probabilità Impatto :</span
                              >
                              {{ item.probabilitaImpatto }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
            </perfect-scrollbar>
          </v-col>
          <v-col v-if="items.length == 0" class="textSerch"
            >Nessun risultato trovato</v-col
          >
        </v-row>
      </v-container>
    </v-sheet>
    <ModalConfirm
      @open-dialog-confirm="updateDialogConfirm"
      :dialogConfirm="openDialogConfirm"
      :text="'Vuoi chiudere il piano?'"
      v-on:confirmed="close()"
    ></ModalConfirm>
    <ModalSelectPG
      @modalSelectPGconfirm="modalSelectPGconfirm"
      @modalSelectPGcancel="modalSelectPGcancel"
      :modalSelectPGIsOpen="modalSelectPGIsOpen"
      :priorityGroups="priorityGroups"
    ></ModalSelectPG>
    <ModalEditServices
      @open-dialog-edit-services="toggleDialogEditServices"
      @confirm-edit-services="confirmEditServices"
      @cancel-edit-services="cancelEditServices"
      :dialogEditServicesIsOpen="dialogEditServicesIsOpen"
      :items="this.fgData.servicesInPlan || []"
      :planId="this.idPlan"
    ></ModalEditServices>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import FailoverGroupDetail from "../components/core/FailoverGroupDetail.vue";
import ModalUpdatePlan from "../components/ModalUpdatePlan.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import ModalEditServices from "@/components/ModalEditServices.vue";
import ModalSelectPG from "@/components/ModalSelectPG.vue";
import planIsActive from "@/mixins/planIsActive.js";
import download from "downloadjs";
import BackActionBar from "../components/core/BackActionBar.vue";
import TextReaderUpdate from "../components/core/TextReaderUpdate.vue";

export default {
  name: "OpPlanFailoverGroupsDetails",
  components: {
    ContextualActionBar,
    FailoverGroupDetail,
    ModalUpdatePlan,
    ModalConfirm,
    ModalEditServices,
    BackActionBar,
    TextReaderUpdate,
    ModalSelectPG,
  },
  mixins: [connectionMixin, planIsActive],
  data() {
    return {
      lockedByOtherUser: true,
      lockId: "",
      fgData: JSON.parse(localStorage.getItem("fgDataFGD")),
      idPlan: this.$route.params.planId,
      items: [],
      itemunimpactedServices: [],
      textUpdate: {
        default: `Il delimitatore dei campi del file csv è il punto e virgola; la prima riga contiene gli header (non viene letta dal server).
                    Nel caso siano presenti meno di 4 campi su una riga, vanno comunque riportati sempre i tre punti e virgola.
                    Il file csv, per essere correttamente interpretato, deve essere formattato in UTF-8`,
      },
      disableAlert: false,
      displayProgress: true,
      impactSummaryValues: [],
      openDialogUpdatePlan: false,
      openDialogConfirm: false,
      nodeFilter: "",
      breadCrumbs: [],
      dialogEditServicesIsOpen: false,
      successMsg: false,
      errorMsg: false,
      priorityGroups: [],
      modalSelectPGIsOpen: false,
      forceReRender: false,
    };
  },
  // watch{
  //   fgDataProp: function (val) {
  //     this.fgData.activation = val;
  //   },
  // },
  mounted() {
    this.getUserProfile();
    this.checkFgData();
  },
  beforeDestroy() {
    this.releaseEditLock();
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),

    statusPlan: function () {
      return this.getActivation ? "Chiudi piano" : "Attiva";
    },
    dragList: function () {
      return this.items;
    },
    dragitemunimpactedServicesList: function () {
      return this.itemunimpactedServices;
    },
  },
  watch: {
    textUpdate: function (val) {
      var bodyData = {
        idPlan: this.idPlan,
        nodiRiavviati: val.nodiRiavviati,
      };
      this.$http
        //Axios post
        .post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_UPDATE_NODES_IN_PLAN"),
          bodyData,
          this.opts
        )
        .then((response) => {
          if (response) {
            var readed =
              "Lette " +
              response.data.readLines +
              " righe dal file caricato in input.";
            var updatedNips =
              "Aggiornati " + response.data.updatedNips + " nodi.";
            var errorCount = "";
            var nodeDisplayLabelNotFound = "";
            var esitoRiavvioNotFound = "";
            var nodeAlreadyCompleted = "";
            if (response.data.errorCount > 0) {
              errorCount =
                "Errori nella lettura del file: " +
                response.data.errorCount +
                ".";

              /* creo la riga del messaggio relativa a nodeDisplayLabelNotFound*/
              if (response.data.nodeDisplayLabelNotFound.length > 0) {
                response.data.nodeDisplayLabelNotFound.forEach((el, index) => {
                  if (
                    index ==
                    response.data.nodeDisplayLabelNotFound.length - 1
                  ) {
                    nodeDisplayLabelNotFound =
                      nodeDisplayLabelNotFound + el + ".";
                  } else {
                    nodeDisplayLabelNotFound =
                      nodeDisplayLabelNotFound + el + ",";
                  }
                });
                nodeDisplayLabelNotFound =
                  "Nodi non trovati: " + nodeDisplayLabelNotFound;
              }
              /* creo la riga del messaggio relativa a esitoRiavvioNotFound*/
              if (response.data.esitoRiavvioNotFound.length > 0) {
                response.data.esitoRiavvioNotFound.forEach((el, index) => {
                  if (index == response.data.esitoRiavvioNotFound.length - 1) {
                    esitoRiavvioNotFound = esitoRiavvioNotFound + el + ",";
                  } else {
                    esitoRiavvioNotFound = esitoRiavvioNotFound + el + ",";
                  }
                });
                esitoRiavvioNotFound =
                  "Esiti non validi: " + esitoRiavvioNotFound;
              }
              /* creo la riga del messaggio relativa a nodeAlreadyCompleted */
              if (response.data.nodeAlreadyCompleted.length > 0) {
                response.data.nodeAlreadyCompleted.forEach((el, index) => {
                  if (index == response.data.nodeAlreadyCompleted.length - 1) {
                    nodeAlreadyCompleted = nodeAlreadyCompleted + el + ",";
                  } else {
                    nodeAlreadyCompleted = nodeAlreadyCompleted + el + ",";
                  }
                });
                nodeAlreadyCompleted =
                  "Nodi già in stato completo: " + nodeAlreadyCompleted;
              }
            }
            /* creo il messaggio completo*/
            alert(
              readed +
                "\n" +
                updatedNips +
                "\n" +
                errorCount +
                "\n" +
                nodeDisplayLabelNotFound +
                "\n" +
                esitoRiavvioNotFound +
                "\n" +
                nodeAlreadyCompleted
            );
            this.disableAlert = true;
          }
          this.fgFiltered();
          response.data.idPlan;
          this.disableAlert = true;
        })
        .catch((error) => {
          this.disableAlert = true;
          alert("Si prega di ricaricare la pagina");
          console.log(error);
        });
    },
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    onstart() {
      this.checkStatusPlan();
      this.getActivePlan();

      if (this.$route.params.planId) {
        this.getPriorityGroups();
        this.getFailoverGroupDetails(this.idPlan);
      } else {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
      if (this.getActivation) {
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Piano operativo",
            to: "/operationalplan",
          },
          {
            text: "Dashboard",
            to: "/opplanimpactandprogress/" + this.$route.params.planId,
          },

          {
            text: "Failover Group",
            to: "/opplanfailovergroups/" + this.$route.params.planId,
          },
          {
            text: "Dettaglio",
            href: "#",
          },
        ];
      } else {
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Piano operativo",
            to: "/operationalplan",
          },
          {
            text: "Dashboard",
            to: "/opplanimpact/" + this.$route.params.planId,
          },

          {
            text: "Failover Group",
            to: "/opplanfailovergroups/" + this.$route.params.planId,
          },
          {
            text: "Dettaglio",
            href: "#",
          },
        ];
      }
    },
    async lockEditResource() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/plan/" + this.idPlan,
          this.opts
        );
        console.log("lockEditResource", response.data);
        this.lockId = response.data.id;
        this.lockedByOtherUser = false;
        this.displayProgress = false;
        return false;
      } catch (error) {
        console.log("error", error);
        if (error.response.status == 423) {
          alert(error?.response.data.username + " sta modificando la risorsa!");
          this.lockedByOtherUser = true;
        } else {
          alert("Si prega di ricaricare la pagina");
        }
        this.displayProgress = false;
        return true;
      }
    },
    async releaseEditLock() {
      if (!this.lockId) {
        return;
      }
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/delete/" + this.lockId,
          this.opts
        );
        console.log("releaseEditLock", response.data);
        this.lockId = "";
        this.displayProgress = false;
      } catch (error) {
        console.log("error", error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data == "readonly") {
          this.$router.push("/");
        } else {
          this.onstart();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    async checkFgData() {
      if (!this.$route.params.failoverGroupId) {
        return;
      }

      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            "/" +
            this.idPlan +
            "/" +
            this.$route.params.failoverGroupId,
          this.opts
        );
        this.fgData = response.data;
        localStorage.setItem("fgDataFGD", JSON.stringify(this.fgData));
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    toggleDialogUpdatePlan(value) {
      this.openDialogUpdatePlan = value;
    },
    async toggleDialogEditServices(value) {
      if (value == true) {
        const locked = await this.lockEditResource();
        if (!locked) {
          this.dialogEditServicesIsOpen = true;
        }
      } else {
        this.releaseEditLock();
        this.dialogEditServicesIsOpen = false;
      }
    },
    cancelEditServices() {
      this.fgData = JSON.parse(localStorage.getItem("fgDataFGD"));
    },
    confirmEditServices(data) {
      console.log(data);
      this.updateServiceInPlan(data);
    },
    async updateServiceInPlan(bodyData) {
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_UPDATE_SERVICE_IN_PLAN"),
          bodyData.toApi,
          this.opts
        );
        console.log(response);
        this.displayProgress = false;
        this.successMsg = true;

        this.fgData.servicesInPlan = bodyData.editedTableValue;

        setInterval(() => {
          this.successMsg = false;
        }, 10000);
      } catch (error) {
        console.log(error);
        this.displayProgress = false;
        this.errorMsg = true;
        setInterval(() => {
          this.errorMsg = false;
        }, 10000);
      }

      localStorage.setItem("fgDataFGD", JSON.stringify(this.fgData));
      window.location.reload();
    },
    managePlanActivation() {
      if (!this.getActivation && this.activeplan.length > 0) {
        alert(
          "Esiste già un piano attivo. Chiuderlo prima di attivarne un altro"
        );
      } else {
        if (this.getActivation === false) {
          this.activePlan(this.idPlan);
        } else {
          this.updateDialogConfirm(true);
        }
      }
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    close() {
      this.closePlan(this.idPlan);
    },
    openCardDetails(idcard) {
      this.$router.push({
        path: "/operatingcarddetails/" + idcard,
        params: {
          fgdata: this.fgData,
        },
      });
    },
    filter() {
      this.fgFiltered();
    },
    async getFailoverGroupDetails(idPlan) {
      if (this.$route.params.failoverGroupId) {
        this.fgData.id = this.$route.params.failoverGroupId;
      }

      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            "/" +
            idPlan +
            "/" +
            this.fgData.id,
          this.opts
        );
        this.items = response.data.servicesInPlan;
        this.itemunimpactedServices = response.data.unimpactedServices;
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    async reloadFailoverGroup(id, priority) {
      var bodyData = {
        idPlan: this.idPlan,
        idService: id,
        insertedPriority: priority,
      };
      this.displayProgress = true;

      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_UPDATE_PRIORITY_SERVICE_IN_PLAN"),
          bodyData,
          this.opts
        );
        this.getFailoverGroupDetails(response.data);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        this.displayProgress = false;
        alert("Si prega di ricaricare la pagina");
      }
    },
    async activePlan(idPlan) {
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_ACTIVE_PLAN") +
            "/" +
            idPlan,
          null,
          this.opts
        );
        console.log(response);
        this.activate(true);
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Dashboard",
            to: "/opplanimpactandprogress/" + this.$route.params.planId,
          },

          {
            text: "Failover Group",
            to: "/opplanfailovergroups/" + this.$route.params.planId,
          },
          {
            text: "Dettaglio",
            href: "#",
          },
        ];
        localStorage.setItem("planIsActive", true);
        this.displayProgress = false;
      } catch (error) {
        this.activate(false);
        this.displayProgress = false;
        if (error.response.status == 409) {
          alert(
            "Il piano è stato già chiuso, non può essere attivato nuovamente"
          );
        } else {
          alert("Si prega di ricaricare la pagina");
        }
      }
    },
    async closePlan(idPlan) {
      this.updateDialogConfirm(false);
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_CLOSE_PLAN") +
            "/" +
            idPlan,
          null,
          this.opts
        );
        console.log(response);
        localStorage.setItem("planIsActive", false);
        this.displayProgress = false;
        this.$router.push("/operationalplan");
      } catch (error) {
        this.activate(true);
        this.displayProgress = false;
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    async fgFiltered() {
      var bodyData = {
        idPlan: this.idPlan,
        idFailovergroup: this.fgData.id,
        node: this.nodeFilter,
      };
      this.displayProgress = true;

      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_FG_FILTERED"),
          bodyData,
          this.opts
        );

        this.items = response.data.servicesInPlan;
        this.itemunimpactedServices = response.data.unimpactedServices;
        this.displayProgress = false;
      } catch (error) {
        this.displayProgress = false;
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    checkStatusPlan() {
      let isActive = localStorage.getItem("planIsActive");
      if (isActive == "false") {
        this.activate(false);
      } else {
        this.activate(true);
      }
    },
    goback() {
      this.$router.push("/opplanfailovergroups/" + this.$route.params.planId);
    },
    async openFileDialog() {
      const locked = await this.lockEditResource();
      if (!locked) {
        this.disableAlert = false;
        document.getElementById("text-reader").value = "";
        document.getElementById("text-reader").click();
      }
    },
    modalSelectPGconfirm(priorityGroup) {
      this.toggleModalSelectPG(false);
      this.exportSystemsByPG(priorityGroup);
    },
    modalSelectPGcancel() {
      this.toggleModalSelectPG(false);
    },
    async toggleModalSelectPG(value) {
      if (value === true) {
        await this.getPriorityGroups();
      }
      this.modalSelectPGIsOpen = value;
    },
    async getPriorityGroups() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            "/" +
            this.idPlan +
            "/" +
            "priorityGroups",
          this.opts
        );

        this.priorityGroups = response.data.values;
        this.displayProgress = false;
      } catch (error) {
        this.displayProgress = false;
        alert(
          "Non è stato possibile caricare i Priority Group. Si prega di ricaricare la pagina"
        );
        console.log(error);
      }
    },
    async exportSystemsByPG(priorityGroup) {
      this.displayProgress = true;
      try {
        const bodyData = {
          planId: this.planId,
          priorityGroup: priorityGroup,
        };
        const response = await this.$http.post(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_SYSTEM") +
            "/export-priorityGroup",
          bodyData,
          this.optsXlsPOST
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        download(response.data, "export-" + priorityGroup + ".xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },
    async exportImpactedSystems() {
      this.displayProgress = true;

      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_SYSTEM") +
            "/" +
            this.idPlan +
            "/" +
            this.fgData.id,
          this.optsXls
        );

        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        download(response.data, this.fgData.name + ".xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },

    updateDialogUpdatePlan(value) {
      if (!value) {
        this.textUpdate = {
          default: `Il delimitatore dei campi del file csv è il punto e virgola; la prima riga contiene gli header (non viene letta dal server).
                    Nel caso siano presenti meno di 4 campi su una riga, vanno comunque riportati sempre i tre punti e virgola.
                    Il file csv, per essere correttamente interpretato, deve essere formattato in UTF-8`,
        };
      }
      this.openDialogUpdatePlan = value;
    },
  },
};
</script>

<style scoped>
.ps {
  height: calc(100vh - 315px);
  width: 100%;
}
.hide {
  display: none !important;
}
.show {
  display: flex !important;
}
input.inputSearch {
  border-radius: 50px;
  background-color: white;
  padding: 5px 10px;
  margin-right: 5px;
}
.btnSearch {
  height: 30px !important;
}
.col.textSerch {
  text-align: center;
  color: white;
}
</style>
