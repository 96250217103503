var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',{staticClass:"white--text pa-2",class:_vm.classStyle},[_c('span',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.fgData.name))]),_vm._v("   "),_c('span',{staticClass:"text-body-2"},[_vm._v("Action: ")]),_vm._v(_vm._s(_vm.fgData.action)+"   "),_c('span',{staticClass:"text-body-2"},[_vm._v("D&D: ")]),_vm._v(_vm._s(_vm.fgData.dd)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium"},[_vm._v(" Attivazione ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:_vm.activationStateValues[0]},'v-icon',attrs,false),on),[_vm._v("mdi-square-rounded")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.activationStateValues[1]))])]),_c('div',{staticClass:"text-caption text-uppercase text-center font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activationStateValues[1])+" ")])],1),_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Sistemi in fault ")]),(_vm.fgData.numSystemFault != 0 && _vm.fgData.numSystemTotal != 0)?_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numSystemFault)+"/"+_vm._s(_vm.fgData.numSystemTotal)+" ("+_vm._s(_vm.calculatePercentage(_vm.fgData.numSystemFault, _vm.fgData.numSystemTotal))+"%) ")]):_c('span',[_vm._v(" 0")])]),(!this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Server NT in Fault ")]),(_vm.fgData.numServerNtFault != 0 && _vm.fgData.numServerNtTotal != 0)?_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numServerNtFault)+"/"+_vm._s(_vm.fgData.numServerNtTotal)+" ("+_vm._s(_vm.calculatePercentage( _vm.fgData.numServerNtFault, _vm.fgData.numServerNtTotal ))+"%)")]):_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" 0%")])]):_vm._e(),(!this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Server UNIX in Fault ")]),(
            _vm.fgData.numServerUnixFault != 0 && _vm.fgData.numServerUnixTotal != 0
          )?_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numServerUnixFault)+"/"+_vm._s(_vm.fgData.numServerUnixTotal)+" ("+_vm._s(_vm.calculatePercentage( _vm.fgData.numServerUnixFault, _vm.fgData.numServerUnixTotal ))+"%)")]):_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" 0%")])]):_vm._e(),(!this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Tempo Stimato ")]),_c('div',{staticClass:"mx-2 font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.fgData.estimatedTime)+" giorni ")])]):_vm._e(),(!this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" RTO cumulativo ")]),_c('div',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.fgData.cumulativeRto)+" giorni ")])]):_vm._e(),(this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Server Completati ")]),(
            _vm.fgData.numServerCompleted != 0 &&
            _vm.fgData.numServerCompletedTotal != 0
          )?_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numServerCompleted)+"/"+_vm._s(_vm.fgData.numServerCompletedTotal)+" ("+_vm._s(_vm.calculatePercentage( _vm.fgData.numServerCompleted, _vm.fgData.numServerCompletedTotal ))+"%)")]):_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" 0%")])]):_vm._e(),(this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Server Dismessi ")]),_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numServerAbandoned))])]):_vm._e(),(this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Priorità Inserita ")]),_c('div',{staticClass:"mx-2 font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.fgData.insertedPriority)+" ")])]):_vm._e(),(this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"1"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" RTO ")]),_c('div',{staticClass:"mx-2 font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.fgData.rto)+" ")])]):_vm._e(),(this.getActivation)?_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"1"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" RPO ")]),_c('div',{staticClass:"mx-2 font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.fgData.rpo)+" ")])]):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","icon":""},on:{"click":_vm.openEditService}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-order-bool-ascending-variant")])],1)]}}])},[_c('span',[_vm._v("Visualizza/Modifica avanzamento servizio")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.updatePriorityDialog(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-priority-high")])],1)]}}])},[_c('span',[_vm._v("Seleziona priorità FG")])]),_c('ModalPriority',{attrs:{"openDialogPriority":_vm.openDialogPriority,"fgInfo":_vm.fgData},on:{"open-dialog-priority":_vm.updatePriorityDialog,"update-priority-value":_vm.updatePriorityValue}}),_c('v-spacer'),_c('span',{class:_vm.statusPlan},[_c('span',{staticClass:"text-caption text-uppercase font-weight-medium mr-2"},[_vm._v(" Avvio lavorazione: ")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.fgData.startDateProcessing))])]),_vm._v("   "),_c('span',{class:_vm.statusPlan},[_c('span',{staticClass:"text-caption text-uppercase font-weight-medium mr-2"},[_vm._v(" Tempo ripristino: ")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.fgData.recoveryTime)+" ")])]),_c('v-spacer'),_c('v-menu',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.itemsMenu),function(item,i){return _c('v-list-item',{key:i,attrs:{"disabled":item.title != 'Scheda Operativa' ? true : false},on:{"click":function($event){return _vm.scheda()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),_c('v-list-item',{on:{"click":function($event){return _vm.getInfoService(true, _vm.fgData.id)}}},[_c('v-list-item-title',[_vm._v("Info")])],1)],2)],1)],1),_c('ModalInfo',{attrs:{"dialogInfo":_vm.openDialogInfo,"info":_vm.infoItems},on:{"open-dialog-info":_vm.updateDialogInfo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }