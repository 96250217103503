<template>
  <v-dialog
    v-model="dialogInfo"
    scrollable
    width="500"
    @click:outside="closeDialogInfoScheda()"
  >
    <v-card>
      <v-card-title class="white--text" :class="typeStyle">{{
        info.displayLabel
      }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="info.environment != 'PROD'" style="color: red">
        * Alcune informazioni sono riferite al servizio di PRODUZIONE.
      </v-card-text>
      <v-card-text style="height: 100vh">
        <v-list two-line>
          <template v-for="(val, key, i) in infoLabels">
            <v-list-item :key="i">
              <v-list-item-content>
                <v-list-item-subtitle>{{ val }}</v-list-item-subtitle>
                <p v-html="info[key]"></p>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="i + 'a'"></v-divider>
          </template>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialogInfoScheda()">
          Chiudi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogInfo: {
      default: false,
      type: Boolean,
    },
    info: {
      type: Object,
    },
  },
  data() {
    return {
      infoLabels: {
        came: "Codice CAME",
        displayLabel: "Display Label",
        // name: "Nome servizio",
        // label: "Label del CI",
        ciType: "Tipo di servizio",
        lifecycleStatus: "Stato del servizio",
        // ucmdbId: "Chiave UCMDB",
        environment: "Ambiente",
        // applicationClass: "Classe applicativa",
        referenteOperativo: "Referente operativo",
        dAndD: "D&D",
        nameAppCat: "Nome del Servizio in application catalog",
        description: "Descrizione del Servizio in application catalog",
        sla: "SLA",
        sox: "SOX",
        state: "Stato del servizio da application catalog",
        emergencyPriority: "Priorità in emergenza",
        serviceClass: "Classe di servizio",
        priorityGroup: "Nome gruppo di priorità",
        prevailingTechnology: "Tecnologia prevalente",
        availability: "Disponibilità",
        rto: "RTO",
        rpo: "RPO",
        annualPeriod: "Periodo annuale",
        monthlyPeriod: "Periodo mensile",
        weeklyPeriod: "Periodo settimanale",
        window: "Finestra di servizio",
        location: "Location",
        offertaServizio: "Offerta servizio",
        perimetroScheda: "Perimetro scheda",
        perimetroSchedaNote: "Perimetro scheda Note",
        perimetroSchedaTest: "Perimetro test",
        perimetroSchedaTestNote: "Perimetro test note",
        dataCondivisione: "Data condivisione scheda",
      },
    };
  },
  computed: {
    typeStyle: function () {
      return {
        blue: this.info.ciType === "business_service",
        red: this.info.ciType === "cluster_software",
        green: this.info.ciType === "infrastructure_service",
        purple: this.info.ciType === "business_application",
      };
    },
  },
  mounted() {
    this.checkNoprod();
  },
  methods: {
    checkNoprod() {
      if (this.info.environment !== "PROD") {
        if (this.infoLabel) {
          this.infoLabel.state = "Stato del servizio da application catalog";
          this.infoLabels.emergencyPriority = "Priorità in emergenza*";
          this.infoLabels.serviceClass = "Classe di servizio*";
          this.infoLabels.availability = "Disponibilità*";
          this.infoLabels.rto = "RTO*";
          this.infoLabels.rpo = "RPO*";
          this.infoLabels.sla = "SLA*";
          this.infoLabels.annualPeriod = "Periodo annuale*";
          this.infoLabels.monthlyPeriod = "Periodo mensile*";
          this.infoLabels.weeklyPeriod = "Periodo settimanale*";
          this.infoLabels.window = "Finestra di servizio*";
          this.infoLabels.location = "Location*";
          this.infoLabels.offertaServizio = "Offerta servizio*";
        }
      }
    },
    closeDialogInfoScheda() {
      this.$emit("open-dialog-info", false);
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
.v-application .green {
  background-color: #007c80 !important;
}
</style>
