<template>
  <v-card>
    <v-card-subtitle class="white--text pa-2" :class="classStyle">
      <span class="text-overline">{{ fgData.name }}</span>
      &emsp;
      <span class="text-body-2">Action:&nbsp;</span>{{ fgData.action }}
      &emsp;
      <span class="text-body-2">D&D:&nbsp;</span>{{ fgData.dd }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div class="text-caption text-uppercase font-weight-medium">
            Attivazione
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :class="activationStateValues[0]"
                >mdi-square-rounded</v-icon
              >
            </template>
            <span>{{ activationStateValues[1] }}</span>
          </v-tooltip>
          <div
            class="text-caption text-uppercase text-center font-weight-medium"
          >
            {{ activationStateValues[1] }}
          </div>
        </v-col>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Sistemi in fault
          </div>
          <span
            v-if="fgData.numSystemFault != 0 && fgData.numSystemTotal != 0"
            class="mx-2 font-weight-bold"
            >{{ fgData.numSystemFault }}/{{ fgData.numSystemTotal }}&nbsp;({{
              calculatePercentage(fgData.numSystemFault, fgData.numSystemTotal)
            }}%)
          </span>
          <span v-else> 0</span>
        </v-col>
        <!-- No Active state -->
        <v-col
          v-if="!this.getActivation"
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Server NT in Fault
          </div>
          <span
            v-if="fgData.numServerNtFault != 0 && fgData.numServerNtTotal != 0"
            class="mx-2 font-weight-bold"
            >{{ fgData.numServerNtFault }}/{{
              fgData.numServerNtTotal
            }}&nbsp;({{
              calculatePercentage(
                fgData.numServerNtFault,
                fgData.numServerNtTotal
              )
            }}%)</span
          >
          <span v-else class="mx-2 font-weight-bold"> 0%</span>
        </v-col>
        <v-col
          v-if="!this.getActivation"
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Server UNIX in Fault
          </div>
          <span
            v-if="
              fgData.numServerUnixFault != 0 && fgData.numServerUnixTotal != 0
            "
            class="mx-2 font-weight-bold"
            >{{ fgData.numServerUnixFault }}/{{
              fgData.numServerUnixTotal
            }}&nbsp;({{
              calculatePercentage(
                fgData.numServerUnixFault,
                fgData.numServerUnixTotal
              )
            }}%)</span
          >
          <span v-else class="mx-2 font-weight-bold"> 0%</span>
        </v-col>
        <v-col
          v-if="!this.getActivation"
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Tempo Stimato
          </div>
          <div class="mx-2 font-weight-bold text-center">
            {{ fgData.estimatedTime }} giorni
          </div>
        </v-col>
        <v-col
          v-if="!this.getActivation"
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            RTO cumulativo
          </div>
          <div class="mx-2 font-weight-bold">
            {{ fgData.cumulativeRto }} giorni
          </div>
        </v-col>
        <!--end No Active state -->
        <!-- Active state -->
        <v-col
          v-if="this.getActivation"
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Server Completati
          </div>
          <span
            v-if="
              fgData.numServerCompleted != 0 &&
              fgData.numServerCompletedTotal != 0
            "
            class="mx-2 font-weight-bold"
            >{{ fgData.numServerCompleted }}/{{
              fgData.numServerCompletedTotal
            }}&nbsp;({{
              calculatePercentage(
                fgData.numServerCompleted,
                fgData.numServerCompletedTotal
              )
            }}%)</span
          ><span v-else class="mx-2 font-weight-bold"> 0%</span>
        </v-col>
        <v-col
          v-if="this.getActivation"
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Server Dismessi
          </div>
          <span class="mx-2 font-weight-bold">{{
            fgData.numServerAbandoned
          }}</span>
        </v-col>
        <v-col
          v-if="this.getActivation"
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Priorità Inserita
          </div>
          <div class="mx-2 font-weight-bold text-center">
            {{ fgData.insertedPriority }}
          </div>
        </v-col>
        <v-col
          v-if="this.getActivation"
          cols="1"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            RTO
          </div>
          <div class="mx-2 font-weight-bold text-center">
            {{ fgData.rto }}
          </div>
        </v-col>
        <v-col
          v-if="this.getActivation"
          cols="1"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            RPO
          </div>
          <div class="mx-2 font-weight-bold text-center">
            {{ fgData.rpo }}
          </div>
        </v-col>
        <!--end Active state-->
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded icon v-bind="attrs" v-on="on" @click="openEditService">
            <v-icon color="primary">mdi-order-bool-ascending-variant</v-icon>
          </v-btn>
        </template>
        <span>Visualizza/Modifica avanzamento servizio</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click="openEditServiceNewTab"
          >
            <v-icon color="primary">mdi-card-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Visualizza/Modifica avanzamento servizio in Nuova Scheda</span>
      </v-tooltip> -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click="updatePriorityDialog(true)"
          >
            <v-icon color="primary">mdi-priority-high</v-icon>
          </v-btn>
        </template>
        <span>Seleziona priorità FG</span>
      </v-tooltip>
      <ModalPriority
        @open-dialog-priority="updatePriorityDialog"
        @update-priority-value="updatePriorityValue"
        :openDialogPriority="openDialogPriority"
        :fgInfo="fgData"
      ></ModalPriority>

      <v-spacer></v-spacer>
      <span :class="statusPlan">
        <span class="text-caption text-uppercase font-weight-medium mr-2">
          Avvio lavorazione:
        </span>
        <span class="text-body-2">{{ fgData.startDateProcessing }}</span>
      </span>
      &emsp;
      <span :class="statusPlan">
        <span class="text-caption text-uppercase font-weight-medium mr-2">
          Tempo ripristino:
        </span>
        <span class="text-body-2">{{ fgData.recoveryTime }} </span>
      </span>
      <v-spacer></v-spacer>

      <v-menu top left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(item, i) in itemsMenu"
            :key="i"
            @click="scheda()"
            :disabled="item.title != 'Scheda Operativa' ? true : false"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getInfoService(true, fgData.id)">
            <v-list-item-title>Info</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
    <ModalInfo
      @open-dialog-info="updateDialogInfo"
      :dialogInfo="openDialogInfo"
      :info="infoItems"
    ></ModalInfo>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import ModalInfo from "@/components/ModalInfo.vue";
import ModalPriority from "@/components/ModalPriority.vue";
export default {
  components: { ModalInfo, ModalPriority },
  mixins: [connectionMixin],
  props: {
    idPlan: {
      default: "",
      String,
    },
    idService: {
      default: "",
      String,
    },
    fgData: Object,
  },
  data() {
    return {
      lockId: "",
      activationStates: {
        notPlanned: { color: "grey--text", label: "Not planned" },
        rework: { color: "red--text", label: " Re-Work " },
        infraServiceOk: {
          color: "green--text",
          label: " Infrastructural Service OK ",
        },
        infraCompleted: {
          color: "green--text",
          label: " Infra Completed ",
        },
        inPipeline: { color: "indigo--text", label: " In pipeline " },
        planned: { color: "amber--text", label: " Planned " },
        businessServiceOk: {
          color: "green--text text--darken-3",
          label: " Business Service OK ",
        },
        businessServiceCompleted: {
          color: "blue--text",
          label: " Business service Completed ",
        },
        amCheck: { color: "purple--text", label: " AM check " },
        toBeNotifiedToCC1: {
          color: "purple--text",
          label: " To be notified to CC1 ",
        },
        default: { color: "grey--text", label: " Non Avviato " },
      },
      itemsMenu: [
        { title: "Scheda Operativa" },
        { title: "Priorità servizio" },
        { title: "Avanzamento servizio" },
      ],
      openDialogInfo: false,
      infoItems: {},
      openDialogPriority: false,
    };
  },
  beforeDestroy() {
    this.releaseEditLock();
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),

    classStyle: function () {
      if (this.fgData.type === "infrastructure_service") {
        return "greenProd";
      }
      return {
        blue: this.fgData.type === "business_service",
        red: this.fgData.type === "cluster_software",
        purple: this.fgData.type === "business_application",
      };
    },
    activationStateValues: function () {
      if (this.getActivation && this.fgData.activationState) {
        var colorstate =
          this.activationStates[
            this.parseActivationState(this.fgData.activationState)
          ].color;
        var labelState =
          this.activationStates[
            this.parseActivationState(this.fgData.activationState)
          ].label;
        return [colorstate, labelState];
      } else {
        return ["grey--text", "non avviato"];
      }
    },
    statusPlan: function () {
      return this.getActivation ? "d-block" : "d-none";
    },
  },
  methods: {
    async lockEditResource() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/plan/" + this.idPlan,
          this.opts
        );
        console.log("lockEditResource", response.data);
        this.lockId = response.data.id;
        this.displayProgress = false;
        return false;
      } catch (error) {
        console.log("error", error);
        if (error.response.status == 423) {
          alert(error?.response.data.username + " sta modificando la risorsa!");
        } else {
          alert("Si prega di ricaricare la pagina");
        }
        this.displayProgress = false;
        return true;
      }
    },
    async releaseEditLock() {
      if (!this.lockId) {
        return;
      }
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/delete/" + this.lockId,
          this.opts
        );
        console.log("releaseEditLock", response.data);
        this.lockId = "";
        this.displayProgress = false;
      } catch (error) {
        console.log("error", error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    parseActivationState(val) {
      let activationStates;
      switch (val) {
        case "NOT planned":
          activationStates = "notPlanned";
          break;
        case "Infrastructural Service OK":
          activationStates = "infraServiceOk";
          break;
        case "Business Service OK":
          activationStates = "businessServiceOk";
          break;
        case "Rework":
          activationStates = "rework";
          break;
        case "AM Check":
          activationStates = "amCheck";
          break;
        case "Infra Completed":
          activationStates = "infraCompleted";
          break;
        case "Business service Completed":
          activationStates = "businessServiceCompleted";
          break;
        case "To be notified to CC1":
          activationStates = "toBeNotifiedToCC1";
          break;
        case "In pipeline":
          activationStates = "inPipeline";
          break;
        case "Planned":
          activationStates = "planned";
          break;
        default:
          activationStates = "default";
      }
      return activationStates;
    },
    calculatePercentage(num, tot) {
      return Math.round(100 * (num / tot));
    },
    updateDialogInfo(value) {
      this.openDialogInfo = value;
    },
    async getInfoService(value, id) {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            id +
            this.$env.value("API_INFO_SERVICE"),
          this.opts
        );
        this.infoItems = response.data;
        this.updateDialogInfo(value);
        this.displayProgress = false;
      } catch (error) {
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    async updatePriorityDialog(value) {
      if (value == true) {
        const locked = await this.lockEditResource();
        if (!locked) {
          this.openDialogPriority = true;
        }
      } else {
        this.releaseEditLock();
        this.openDialogPriority = false;
      }
    },
    updatePriorityValue(id, priority, reload) {
      this.$emit("reload-failover-group", id, priority, reload);
    },
    openEditService() {
      this.fgData.idPlan = this.idPlan;
      this.fgData.idFailover = this.idService;
      localStorage.setItem("fgDataService", JSON.stringify(this.fgData));
      this.$router.push({
        name: "OpEditServiceProgress",
      });
    },
    openEditServiceNewTab() {
      this.fgData.idPlan = this.idPlan;
      this.fgData.idFailover = this.idService;
      localStorage.setItem("fgDataService", JSON.stringify(this.fgData));
      let routeData = this.$router.resolve({
        name: "OpEditServiceProgress",
      });
      window.open(routeData.href, "_blank");
    },
    scheda() {
      this.$router.push({
        name: "OperatingCardDetails",
        params: {
          id: this.fgData.id,
          backurl: "/opplanfailovergroups/" + this.idPlan,
        },
      });
    },
  },
};
</script>

<style>
.greenProd {
  background: #007c80;
}
</style>
